import {
    getLocalStorage,
    removeLocalStorage,
    setLocalStorage,
} from "./localStorage-helpers"
import {
    getSessionStorage,
    removeSessionStorage,
    setSessionStorage,
} from "./sessionStorage-helpers"
import {
    VAES_USER_ACCESS_TOKEN_KEY,
    VAES_USER_PREVIEW_TOKEN_KEY,
    VAES_USER_REFRESH_TOKEN_KEY,
    VAES_USER_REMEMBER_ME,
} from "../../lib/constants"
import { queryClient } from "../../api/client/reactQuery"

export const getAccessToken = () => {
    if (getLocalStorage(VAES_USER_REMEMBER_ME)) {
        return getLocalStorage(VAES_USER_ACCESS_TOKEN_KEY)
    } else {
        return getSessionStorage(VAES_USER_ACCESS_TOKEN_KEY)
    }
}

export const getRefreshToken = () => {
    if (getLocalStorage(VAES_USER_REMEMBER_ME)) {
        return getLocalStorage(VAES_USER_REFRESH_TOKEN_KEY)
    } else {
        return getSessionStorage(VAES_USER_REFRESH_TOKEN_KEY)
    }
}

export const setPreviewToken = (token: string) => {
    setLocalStorage(VAES_USER_PREVIEW_TOKEN_KEY, token)
}
export const getPreviewToken = () => {
    return getLocalStorage(VAES_USER_PREVIEW_TOKEN_KEY)
}
export const removePreviewToken = () => {
    if (!getPreviewToken()) {
        return
    }

    removeLocalStorage(VAES_USER_PREVIEW_TOKEN_KEY)
    // invalidate preview token query
    queryClient.resetQueries()
    // navigate user to admin portal
    window.location.href = "/admin"
}

export const setTokens = (access: string, refresh: string) => {
    if (getLocalStorage(VAES_USER_REMEMBER_ME)) {
        setLocalStorage(VAES_USER_ACCESS_TOKEN_KEY, access)
        setLocalStorage(VAES_USER_REFRESH_TOKEN_KEY, refresh)
    } else {
        setSessionStorage(VAES_USER_ACCESS_TOKEN_KEY, access)
        setSessionStorage(VAES_USER_REFRESH_TOKEN_KEY, refresh)
    }
}

export const removeTokens = () => {
    if (getLocalStorage(VAES_USER_REMEMBER_ME)) {
        removeLocalStorage(VAES_USER_ACCESS_TOKEN_KEY)
        removeLocalStorage(VAES_USER_REFRESH_TOKEN_KEY)
        removeLocalStorage(VAES_USER_PREVIEW_TOKEN_KEY)
    } else {
        removeSessionStorage(VAES_USER_ACCESS_TOKEN_KEY)
        removeSessionStorage(VAES_USER_REFRESH_TOKEN_KEY)
        removeSessionStorage(VAES_USER_PREVIEW_TOKEN_KEY)
    }
}
